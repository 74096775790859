import classNames from 'classnames';
import { motion, MotionStyle } from 'framer-motion';
import { useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Tooltips from '../../components/bootstrap/Tooltips';
import ThemeContext from '../../contexts/themeContext';
import useAsideTouch from '../../hooks/useAsideTouch';
import { dashboardMenu } from '../../menu';
import Brand from '../Brand/Brand';
import Navigation, { NavigationLine } from '../Navigation/Navigation';
import User from '../User/User';
import {
	accounts,
	complains,
	consignee,
	containers,
	customers,
	exports,
	loadPlan,
	price,
	settings,
	subuser,
	vehicle,
} from './menus';
interface AuthState {
	auth: any;
}

const Aside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { permissions, user } = useSelector((state: AuthState) => state.auth);

	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();

	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';

	const constraintsRef = useRef(null);

	const [doc] = useState(false);

	return (
		<>
			<motion.aside
				style={asideStyle as MotionStyle}
				className={classNames(
					'aside',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					},
				)}>
				<div className='aside-head'>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
					{/* <img
						src={`${process.env.REACT_APP_BASE_URL}/images/logo.jpg`}
						alt=''
						height={60}
						width={'100%'}
					/> */}
				</div>
				<div className='aside-body'>
					<Navigation menu={dashboardMenu} id='aside-dashboard' />
					<NavigationLine />
					{!doc && (
						<>
							{permissions?.['customers.index'] && (
								<Navigation menu={customers(permissions)} id='customers-pages' />
							)}

							{user?.role == 4 && (
								<Navigation menu={subuser()} id='customers-pages' />
							)}
							{permissions?.['consignee.index'] && (
								<Navigation menu={consignee(permissions)} id='consignee-pages' />
							)}
							{/* <Navigation menu={vehiclePages(permissions)} id='aside-demo-pages' /> */}
							<Navigation menu={vehicle(permissions)} id='vehicle-pages' />
							{permissions?.['export.index'] && (
								<Navigation menu={exports(permissions)} id='export-pages' />
							)}
							{permissions?.['container.index'] && (
								<Navigation menu={containers(permissions)} id='containers-page' />
							)}
							{permissions?.['price.index'] && (
								<Navigation menu={price(permissions)} id='price-page' />
							)}

							{/* {user?.role !== 4 && (
								<Navigation
									menu={pricing(permissions, user?.role)}
									id='prices-page'
								/>
							)} */}
							{permissions?.['complains.index'] && (
								<Navigation menu={complains(permissions)} id='complain-page' />
							)}

							{permissions?.['load-plans.index'] && (
								<Navigation menu={loadPlan(permissions)} id='load-plan-page' />
							)}

							<Navigation menu={accounts(permissions)} id='accounts-page' />
							<NavigationLine />
							<Navigation
								menu={settings(permissions, user?.role)}
								id='settings-page'
							/>
							<NavigationLine />
							{/* <Navigation menu={demoPages} id='aside-demo-pages' />
							<NavigationLine />
							<Navigation menu={layoutMenu} id='aside-menu' />
							<NavigationLine /> */}
						</>
					)}

					{/* {asideStatus && doc && <div className='p-4'>Documentation</div>} */}
				</div>
				<div className='aside-foot'>
					{/* <nav aria-label='aside-bottom-menu'>
						<div className='navigation'>
							<div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() => {
									setDoc(!doc);
								}}
								data-tour='documentation'>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon
											icon={doc ? 'ToggleOn' : 'ToggleOff'}
											color={doc ? 'success' : undefined}
											className='navigation-icon'
										/>
										<span className='navigation-text'>
											{t('menu:Documentation')}
										</span>
									</span>
									<span className='navigation-link-extra'>
										<Icon
											icon='Circle'
											className={classNames(
												'navigation-notification',
												'text-success',
												'animate__animated animate__heartBeat animate__infinite animate__slower',
											)}
										/>
									</span>
								</span>
							</div>
						</div>
					</nav> */}
					<User />
				</div>
			</motion.aside>
			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title='Toggle Aside' flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
};

export default Aside;
